import { MagicUseCase } from '../magicUseCase';

export class CreateRentalApplicationUseCase extends MagicUseCase {
  protected async runLogic(unitId?: string) {
    this.getState().initializeRentalApplication();
    let path = `/rental-applications/applicant-information`;
    if (unitId) {
      path = `${path}?unitId=${unitId}`;
    }
    this.navigate(path);
  }
}
